import { Outlet } from "react-router-dom";

import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

import styles from './AuthLayout.module.css';

export const AuthLayout = () => {

    return (
        <>
            {/* Navbar */}
            <Navbar />
            {/* Navbar */}
            <div className={"container-fluid " + styles.authContainer}>
                <div className="row">
                    <div className="offset-xs-0 col-xs-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-3 col-xl-6 offset-xxl-4 col-xxl-4">
                        <div className={styles.formContainer}>
                            <div className={styles.outletContainer}>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer */}
            <Footer />    
            {/* Footer */}
        </>
    )
}
